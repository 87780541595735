@import '@ciscodesignsystems/cds-react-stepper/index.css';
@import '@ciscodesignsystems/cds-react-container/index.css';
@import '@ciscodesignsystems/cds-react-header/index.css';
@import '@ciscodesignsystems/cds-react-button/index.css';
@import '@ciscodesignsystems/cds-react-text-input/index.css';
@import '@ciscodesignsystems/cds-react-textarea/index.css';
@import '@ciscodesignsystems/cds-react-toggle/index.css';
@import '@ciscodesignsystems/cds-react-logos/index.css';
@import '@ciscodesignsystems/cds-react-flex/index.css';
@import '@ciscodesignsystems/cds-react-breadcrumb/index.css';
@import '@ciscodesignsystems/cds-react-sticky-bar/index.css';
@import '@ciscodesignsystems/cds-react-tag/index.css';
@import '@ciscodesignsystems/cds-react-notification/index.css';
@import '@ciscodesignsystems/cds-react-radio/index.css';
@import '@ciscodesignsystems/cds-react-spinner/index.css';
@import '@ciscodesignsystems/cds-react-drawer/index.css';
@import '@ciscodesignsystems/cds-react-popover/index.css';
@import '@ciscodesignsystems/cds-react-modal/index.css';
@import '@ciscodesignsystems/cds-react-card/index.css';
@import '@ciscodesignsystems/cds-react-tab/index.css';
@import '@ciscodesignsystems/cds-react-checkbox/index.css';
@import '@ciscodesignsystems/cds-magnetic-theme-web/css/dist/token-theme-light-variables.css';
@import '@ciscodesignsystems/cds-react-footer/index.css';
@import '@ciscodesignsystems/cds-react-collapse/index.css';
@import '@ciscodesignsystems/cds-react-badge/index.css';
@import '@ciscodesignsystems/cds-react-table/index.css';
@import '@ciscodesignsystems/cds-react-toaster/index.css';
@import '@ciscodesignsystems/cds-react-skeleton/index.css';
@import '@ciscodesignsystems/cds-react-select/index.css';
@import '@ciscodesignsystems/cds-react-empty-state/index.css';
@import '@ciscodesignsystems/cds-react-dropdown/index.css';
@import '@ciscodesignsystems/cds-react-upload/index.css';
@import '@ciscodesignsystems/cds-react-progress-bar/index.css';
@import '@ciscodesignsystems/cds-react-nav/index.css';
@import '@ciscodesignsystems/cds-react-heading/index.css';
@import '@ciscodesignsystems/cds-react-text/index.css';
@import '@ciscodesignsystems/cds-react-tooltip/index.css';
@import '@ciscodesignsystems/cds-react-divider/index.css';
@import '@ciscodesignsystems/cds-react-label/index.css';
@import '@ciscodesignsystems/cds-react-copy-to-clipboard/index.css';
@import '@ciscodesignsystems/cds-react-date-time-picker/index.css';

@font-face {
  font-family: 'Sharp Sans';
  src: url('./fonts/sharp-sans/SharpSans-Bold.woff') format('woff');
}

body {
  margin: 0;
  background-color: #f7f7f7;
  /* CSESC-3310 To make sure, safari , firefox and chrome is identical fonts
Read below article :
https://szafranek.net/blog/2009/02/22/font-smoothing-explained/
https://www.smashingmagazine.com/2012/04/a-closer-look-at-font-rendering/
*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-text-stroke-width: 0.2px;
}

// using this only for standalone app
.layout-with-nav {
  min-height: 100vh;
}

.cutom-divider-margin {
  margin-top: 16px;
  margin-bottom: 16px;
}

.tool-tip-z-index {
  z-index: 10000;
}

.divider-custom {
  border-top-color: rgb(225, 228, 232);
  border-top-style: solid;
  border-top-width: 1px;
}

.divider-custom-drawer {
  width: 400px;
  margin-left: -24px !important;
  border-top-color: rgb(225, 228, 232);
  border-top-style: solid;
  border-top-width: 1px;
}

.toast-message {
  z-index: 10000;
  position: fixed;
  top: 80px;
  right: 24px;
  width: 470px;
}

.toasts-message {
  top: 79px !important;
  max-width: 467px;
  white-space: pre-line;
  word-break: break-word;

  &.cds-toaster.cds-toaster--placement-top-right {
    right: 36px;
  }
}

.common-banner {
  background-color: #d9e7ff;
  padding: 12px 16px;
  border-radius: 6px;
}

.service-request-banner {
  background-color: #fcf5d4;
}

.banner-pending-icon {
  margin-top: 2px;
  color: #5b92f0;
}

.banner-disabled-icon {
  margin-top: 2px;
  color: #889099;
}

.banner-request-icon {
  color: #d9b216;
  margin-top: 2px;
}

.menu-item-size {
  padding: 8px 10px;
  margin: 2px;
  max-height: 20px;
}

.menu-delete-user-color {
  color: #c24632;
}

.menu-item-position .ant-menu-title-content {
  position: relative;
  bottom: 10px;
}

.menu-size {
  background: #ffffff;
  border: 2px solid #889099;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  min-width: 200px;
}

.main-container {
  margin-left: 310px;
}

.button-link {
  text-decoration: none;
}

.card-margin {
  margin-right: 48px;
  margin-top: 32px;
}

.mr3 {
  margin-right: 36px !important;
}

.mt1 {
  margin-top: 10px !important;
}

.stepper-height {
  min-height: 410px;
}

.btn-ellipsis {
  &.ant-dropdown-open {
    background-color: #cce1ff;
  }
}

.asterisk-color {
  color: rgb(194, 70, 50);
}

.text-center {
  text-align: center;
}

.input-box-width {
  max-width: 320px;
}

.input-error-width {
  max-width: 320px;
}

.stepper-card {
  border-radius: 12px;
  padding: 0;
  border-color: #ffffff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
}

.align-items-center {
  align-items: center !important;
}

.m0 {
  margin: 0 !important;
}

.breadcrumb-content-width {
  width: fit-content !important;
}

.custom-font-family {
  font-family:
    Inter,
    -apple-system,
    system-ui,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif;
}

.custom-font-family-type-1 {
  font-family:
    Inter,
    -apple-system,
    'Segoe UI',
    Roboto,
    'BlinkMacSystemFont',
    Arial,
    sans-serif;
}

.custom-tertiary-button-toaster-fix {
  margin-bottom: 3px !important;
}

.mx-w400 {
  max-width: 400px;
}

.max-width-500 {
  max-width: 500px;
  box-sizing: border-box;
}

.w500 {
  width: 500px;
  box-sizing: border-box;
}

.ml2 {
  margin-left: 2em;
}

.fs16 {
  font-size: 16px !important;
}

.mt2 {
  margin-top: 16px !important;
}

.pl0i {
  padding-left: 0 !important;
}

.pl0 {
  padding-left: 0;
}

.pr0 {
  padding-right: 0;
}

.custom-drawer-heading-alignment > .cds-drawer__header__content {
  word-break: break-word;
}

.custom-drawer-heading-alignment > .cds-drawer__header__close-button {
  align-self: flex-start;
  padding-top: 5px;
}

.generic-drop-down-z-index {
  z-index: 99;
}

.ant-table-tbody {
  .ant-table-cell {
    word-break: break-all;
  }
}

.table-compact-empty-state {
  .cds-table__row__column--empty {
    padding-bottom: 12px;
  }
}

.cds-table tr.cds-table__row > td.cds-table__cell {
  vertical-align: middle;
  overflow-wrap: anywhere;
}

.table-ellipsis-column tr.cds-table__row > td.cds-table__cell:last-child {
  padding: 7px 12px;
}

[class*='cds-table__pagination__page'],
[class*='cds-table__settings-drawer'],
.cds-table__pagination__item,
.cds-table__bulk-actions {
  font-family:
    Inter,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif;
}

// TODO: Remove these styles if the color changes are global and should be done by Magnetic team in their component
.table-select-input.select-role.selected-options .cds-select__placeholder {
  color: var(--base-text-default);
}
